<template>
  <div style="margin: 5px">
    <section
      v-if="withRsvp"
      class="col-sm-12 text-center"
      style="padding: 1px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header" style="font-weight: bold">
        <img
          v-lazy="this.logo + '?w=40&h=40&fit=clamp'"
          alt="tulum.party"
          height="40px"
          width="40px"
          style="border-radius: 300px"
        />
        {{ topSectionName }}
      </h4>

      <div style="margin:25px">
        <slot>
        </slot>
        <RsvpViaDialog
          :text="ctaText"
        ></RsvpViaDialog>
      </div>
    </section>

    <hr :style="{height: '2px', backgroundColor: `${this.primaryColor}`}">
    <section
      class="col-sm-12"
      style="padding: 1px;padding-bottom: 40px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">



    <h4 class="section-header">
        <i class="tim-icons icon-settings"></i>&nbsp;TOOLS
      </h4>
      <div style="margin: 10px;padding: 10px" class="text-left">
        <small >Tools to elevate your party time.</small>
      </div>
      <MiniCard
        title="Party Blog"
        text="TULUM DECODED"
        link="/blog"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>
      <MiniCard
        title="Community"
        text="+ 4000 Members"
        link="/l?u=https://t.me/tulumparty"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>

      <MiniCard
        title="DJ Schedule"
        text="Who is playing?"
        link="/tulum-dj-schedule"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>
      <MiniCard
        title="Event Calendar"
        text="Do Not Miss A Thing !"
        link="/tulum-party-mexico-event-calendar"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>
      <MiniCard
        title="Top Clubs"
        text="Dance & Have Fun"
        link="/tulum-clubs"
        image="https://imgix.cosmicjs.com/5a39f560-c8f3-11ee-9ce5-59949019255e-day-zero-main-stage.jpg"
      ></MiniCard>

    </section>

    <hr :style="{height: '2px', backgroundColor: `${this.primaryColor}`}">

    <section
      class="col-sm-12"
      style="padding: 1px;padding-bottom: 40px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">
      <h4 class="section-header">
        <i class="tim-icons icon-trophy" @click="clicked=true"></i>&nbsp; POPULAR
      </h4>

      <DynamicLinks :links="links"
                    :style="{ background: `linear-gradient(45deg, black, ${this.primaryColor})` }"
      ></DynamicLinks>

    </section>


    <section
      class="col-sm-12"
      style="padding: 1px"
      :style="{ background: `linear-gradient(${this.primaryColor}, black )` }">

      <h4 class="section-header">
        <i class="tim-icons icon-vector"></i>
        CONNECT
      </h4>
      <hr style="height: 2px" :style="{ backgroundColor: this.primaryColor }"/>
      <ul class="list-unstyled">

        <li> <img v-lazy="instagramLogo" height="20px" width="20px"> <a href="https://www.instagram.com/tulum.party/" target="_blank">Instagram</a></li>
        <li><img v-lazy="faceLogo" height="20px" width="20px"> <a href="https://www.facebook.com/tulum.party">Facebook</a></li>
      </ul>
    </section>
    <div class="card text-center centered" style="padding: 30px">
      <img v-lazy="tulumPartyLogo" height="15" width="105" class="centered" alt="tulum party logo">
      <small style="font-weight: lighter;font-size: 10px">PLAN A | SIDE B</small>
    </div>
  </div>
</template>
<script>
import DynamicLinks from "@/components/DynamicLinks"
import MiniCard from '@/components/Cards/MiniCard'
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog"
// import ImgCard from "@/components/Cards/ImgCard";

export default {
  name: 'SmartMenu',
  data: () => {
    return {
      clicked: false,
      sanDJ: 'https://imgix.cosmicjs.com/f9568ec0-4160-11ef-a504-63e081e4680f-santo-dj.jpg',
      tpLogo: 'https://imgix.cosmicjs.com/07af7270-5002-11ef-b1ea-f56c65dfade9-tulum-party-insta.jpg',
      instagramLogo: 'https://imgix.cosmicjs.com/a9bc32f0-3fd5-11ec-99ca-fbde970234a8-image.png',
      faceLogo: 'https://imgix.cosmicjs.com/c49b9530-3fd4-11ec-99ca-fbde970234a8-image.png',
      tulumPartyLogo: 'https://imgix.cosmicjs.com/3acdf350-9b87-11ee-b34c-99d1d89e42eb-tp-logo-40pt.png?w=105&h=15&fit=clamp'

    }
  },
  components: {DynamicLinks, MiniCard, RsvpViaDialog},
  props: {
    links: {},
    logo: '',
    primaryColor: {
      type: String,
      default: '#323232'
    },
    withRsvp: {
      type: Boolean,
      default: true,
    },
    topSectionName: {
      type: String,
      default: 'GET IN TOUCH'
    },
    ctaText: {
      type: String,
      default: 'Hit us up!'
    },
  }
}
</script>
